import React from "react";
// import Avatar from "../Avatar/Avatar";
import { Link } from "react-router-dom";
import defaultProfile from "../../Assets/default-profile.png";
import { MdDelete } from "react-icons/md";

const ChatListing = ({ data, active, onClick, onDelete }) => {
  return (
    <>
      <div
        className={`w-full hidden cursor-pointer md:flex justify-between items-center py-2 border-b ${
          active ? "bg-blue-100" : ""
        }`}
      >
        <div
          onClick={onClick}
          className="w-full flex justify-start items-center px-3"
        >
          <div className="max-w-[32px] max-h-[32px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
            <img
              className="w-full h-full "
              src={
                data?._id?.sender?.photo
                  ? process.env.REACT_APP_IMAGE_URL + data?._id?.sender?.photo
                  : defaultProfile
              }
              alt="avatar"
            />
          </div>
          <div className=" ml-2 flex justify-start items-start flex-col w-full">
            <h4 className="text-gray-500 text-[12px] satoshi-500 lg:text-[14px] max-w-[140px]">
              {data?._id?.sender?.firstName + " " + data?._id?.sender?.lastName}
            </h4>
            {data?.unread > 0 && (
              <p className="text-[10px] satoshi-700 text-black">
                {data?.unread} new message
              </p>
            )}
          </div>
        </div>
        <MdDelete
          onClick={onDelete}
          className="text-[16px] md:text-[19px] xl:text-[22px]  mr-3 text-red-600 hover:text-[--primary]"
        />
      </div>

      <div className=" md:hidden w-full flex justify-between items-center py-2 border-b">
        <Link
          to={`/messages/${data?._id?.sender?._id}`}
          className="md:w-[65%] lg:w-[60%] xl:w-1/2 flex justify-start items-center px-3"
        >
          <div className="max-w-[32px] max-h-[32px] min-w-[30px] min-h-[30px] rounded-full overflow-hidden">
            <img
              className="w-full h-full"
              src={
                data?._id?.sender?.photo
                  ? process.env.REACT_APP_IMAGE_URL + data?._id?.sender?.photo
                  : defaultProfile
              }
              alt="avatar"
            />
          </div>
          <div className=" ml-2 flex justify-start items-start flex-col w-full">
            <h4 className="text-gray-500 text-[12px] satoshi-500 lg:text-[14px] max-w-[140px]">
              {data?._id?.sender?.firstName + " " + data?._id?.sender?.lastName}
            </h4>
            {data?.unread > 0 && (
              <p className="text-[10px] satoshi-700 text-black">
                {data?.unread} new message
              </p>
            )}
          </div>
        </Link>
        <MdDelete
          onClick={onDelete}
          className="text-[16px] md:text-[19px] xl:text-[22px]  mr-3 text-red-600 hover:text-[--primary]"
        />
      </div>
    </>
  );
};

export default ChatListing;
