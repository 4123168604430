import React from "react";
import defaultProfile from "../../Assets/default-profile.png"

const Avatar = ({ imageRef }) => {
  return (
    <img
      className="w-[30px] h-[30px] rounded-full"
      src={imageRef ? process.env.REACT_APP_IMAGE_URL+imageRef : defaultProfile} 
      alt="avatar"
    />
  );
};

export default Avatar;
